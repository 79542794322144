import React from "react";

import "./TeamMember.style.css";

interface ITeamMemberProps {
  image: any;
  name: string;
  role: string;
  desc: string;
  link?: string;
}

export default function TeamMember({
  image,
  name,
  role,
  desc,
  link,
}: ITeamMemberProps) {
  let xlink = null;
  if (link) {
    xlink = (
      <div className="member-link">
        <a href={link} className="text-coolGray-600" target="blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 32 32"
            className="w-4 h-4"
          >
            <path d="M8.268 28h-5.805v-18.694h5.805zM5.362 6.756c-1.856 0-3.362-1.538-3.362-3.394s1.505-3.362 3.362-3.362 3.362 1.505 3.362 3.362c0 1.856-1.506 3.394-3.362 3.394zM29.994 28h-5.792v-9.1c0-2.169-0.044-4.95-3.018-4.95-3.018 0-3.481 2.356-3.481 4.794v9.256h-5.799v-18.694h5.567v2.55h0.081c0.775-1.469 2.668-3.019 5.492-3.019 5.875 0 6.955 3.869 6.955 8.894v10.269z"></path>
          </svg>
        </a>
      </div>
    );
  }

  return (
    <div className="member-root">
      <img alt={name} className="member-image" src={image} />
      <div className="member-desc">
        <h4 className="text-xl font-semibold">{name}</h4>
        <p className="text-lg font-semibold text-orange-500">{role}</p>
        {/* <p className="text-center text-coolGray-600">{desc}</p> */}
        {xlink}
      </div>
    </div>
  );
}
