import React from "react";

import Layout from "../components/Layout";

import TeamSVG from "../assets/team.svg";
import TeamMember from "../components/TeamMember";

import emeline from "../assets/emeline.jpg";
import adrien from "../assets/adrien.jpg";
import marius from "../assets/marius.jpg";
import alexandre from "../assets/alexandre.jpg";
import yvonne from "../assets/yvonne.jpg";
import matheo from "../assets/matheo.jpg";
import georges from "../assets/georges.jpg";

function AboutUs() {
  return (
    <Layout title="A propos">
      <section className="text-coolGray-800">
        <div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-around">
          <div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
            <h1 className="text-5xl font-bold leading-none sm:text-6xl">
              Qui sommes-nous ?
            </h1>
            <p className="mt-6 mb-8 text-lg sm:mb-12">
              Chez KerNel Biomedical, nous avons pour objectif de{" "}
              <span className="font-semibold text-orange-500">
                faciliter la vie des chercheurs{" "}
              </span>
              en développant des nouvelles solutions numériques au service de la
              santé.
            </p>
          </div>
          <div className="flex items-center justify-center w-5/6 lg:max-w-lg lg:w-full md:w-1/2">
            <TeamSVG className="object-cover object-center" />
          </div>
        </div>
      </section>
      <div className="bg-coolGray-100 text-coolGray-800">
        <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold sm:text-4xl">
              Notre approche
            </h2>
          </div>
          <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-orange-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <div className="ml-3">
                <dt className="text-lg font-medium">
                  Rendre accessible l'innovation
                </dt>
                <dd className="mt-2 text-coolGray-600">
                  Nous avons pour objectif de favoriser l'émergence de nouvelles
                  technologies au service de la recherche et de la pratique
                  clinique, que ce soit dans le domaine de l'assistance
                  respiratoire ou de la santé au sens large
                </dd>
              </div>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-orange-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <div className="ml-3">
                <dt className="text-lg font-medium">
                  Mettre à contribution notre savoir-faire
                </dt>
                <dd className="mt-2 text-coolGray-600">
                  Notre expertise dans le traitement des signaux médicaux et la
                  modélisation des systèmes biologiques constitue une base
                  solide pour la recherche et l'innovation au service de la
                  santé
                </dd>
              </div>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-orange-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <div className="ml-3">
                <dt className="text-lg font-medium">Partager une vision</dt>
                <dd className="mt-2 text-coolGray-600">
                  À l'écoute de vos besoins, nous avons pour objectifs communs
                  une meilleure qualité des soins, une prise en charge
                  personnalisée des patients et des process simplifiées à toutes
                  les étapes de la recherche
                </dd>
              </div>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-orange-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <div className="ml-3">
                <dt className="text-lg font-medium">
                  Préserver une démarche agile
                </dt>
                <dd className="mt-2 text-coolGray-600">
                  Notre structure à taille humaine nous permet de nous adapter
                  rapidement : nous apprenons en permanence, acquérons de
                  nouvelles compétences et appliquons des stratégies innovantes
                  pour servir au mieux nos clients et construire des relations
                  solides
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
      <section className="py-6 bg-coolGray-100 text-coolGray-800">
        <div className="container p-4 mx-auto space-y-16 sm:p-10">
          <div className="space-y-4">
            <h3 className="text-2xl font-bold leading-none sm:text-5xl">
              Notre équipe
            </h3>
            <p className="max-w-2xl text-coolGray-600">
              Rencontrez l'équipe derriere KerNel Biomedical !
            </p>
          </div>
          <div className="flex justify-center">
            <div className="flex flex-wrap justify-center w-full max-w-7xl">
              <TeamMember
                image={emeline}
                name="Émeline Fresnel"
                role="Cofondatrice et CEO"
                desc="Docteure en ingénierie biomédicale, Emeline fait l’interface avec les chercheurs pour définir les orientations stratégiques de KerNel."
                link="https://www.linkedin.com/in/emelinefresnel/"
              />
              <TeamMember
                image={adrien}
                name="Adrien Kerfourn"
                role="Cofondateur et CTO"
                desc="Docteur en sciences de l’ingénieur, Adrien s’attache à développer des logiciels performants et sécurisés pour la recherche en santé et gère la relation client."
                link="https://www.linkedin.com/in/adrienkerfourn/"
              />
              <TeamMember
                image={marius}
                name="Marius Lebret"
                role="Expert paramédical"
                desc="-------------------------------------------------------------------------------------------------------------------------------------"
                link="https://www.linkedin.com/in/marius-lebret-9547b88a/"
              />
              <TeamMember
                image={yvonne}
                name="Yvonne Baye"
                role="Responsable du développement commercial"
                desc="Titulaire d’un BTS en relation client, Yvonne gère le développement commercial de KerNel et la relation client."
                link="https://www.linkedin.com/in/yvonne-baye/"
              />
              <TeamMember
                image={matheo}
                name="Matheo Richard"
                role="Ingénieur biomédical"
                desc="-------------------------------------------------------------------------------------------------------------------------------------"
              />
              <TeamMember
                image={georges}
                name="Georges"
                role="Sujet de test"
                desc="-------------------------------------------------------------------------------------------------------------------------------------"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default AboutUs;
